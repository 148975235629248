<template>
    <div id="searchbox">
        <div class="hadheight"></div>
        <Header />
        <div class="crumbsbox">
            <div class="basecont">
                <router-link to="/">首页</router-link>
                <span>&gt;</span>
                <a href="javascript:;" class="on">{{xie}}</a>
            </div>
        </div>
        <div class="searchcenter base100">
			<div class="basecont">
				<div class="cont">
					<div class="search-sect1">
						<div class="f_tit basefont30">
							关键词搜索
						</div>
						<form action="" method="">
							<input type="text" name="" v-model="keywordVal" placeholder="请搜索关键词" />
							<button type="submit" @click.prevent="searchSubmit()" class="icon iconfont">&#xe608;</button>
						</form>
					</div>
					<div class="searchcenter_cont">
						<div class="item" v-if="searProData!=''">
							<div class="f_tit basefont24">
								产品({{proCount}})
							</div>
							<template v-for="item in searProData">
								<router-link :to="{name: 'Productlistinfo',params:{id:item.id,pan: 1}}"
								 :key="item.id"
								 class="f_list">
									<div class="f_tit1" v-html="addEmphasis(item.spuName)"></div>
									<div class="f_text" v-html="addEmphasis(item.spuDesc)"></div>
								</router-link>
							</template>
							
							<!-- <div class="f_list">
								<a href="" class="f_tit1">
									相关标题文字相关标题文字相关标题文字相关标题文字
								</a>
								<a href="" class="f_text">
									文字描述文字描述文字描述文字描述文字<span>CMS80F252</span>描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字描述文字...
								</a>
								<div class="link1">
									<a href="">应用</a><i class="icon iconfont">&#xe6af;</i>
									<a href="">医疗电子</a><i class="icon iconfont">&#xe6af;</i>
									<a href="">个人医疗</a><i class="icon iconfont">&#xe6af;</i>
									<a href="">体温计</a>
								</div>
							</div> -->
							<router-link to="/product" class="but">更多关于产品的结果&gt;</router-link>
						</div>
						<div class="item" v-if="searBrandData!=''">
							<div class="f_tit basefont24">
								品牌({{brandCount}})
							</div>
							<template v-for="item in searBrandData">
								<router-link :to="{name: 'Brandlistinfo',params: {userid: item.id}}"
								 :key="item.id"
								 class="f_list">
									<div class="f_tit1" v-html="addEmphasis(item.name)"></div>
									<div class="f_text" v-html="addEmphasis(item.profile)"></div>
								</router-link>
							</template>

							<router-link to="/brand" class="but">更多关于品牌的结果&gt;</router-link>
						</div>
					</div>
					<div class="product_cont">
						<div class="item" v-for="(item,index) in prolist" :key="index">
							<div class="img">
								<img :src="item.spuDefaultImg" :alt="item.spuName">
							</div>
							<div class="font">
								<div class="tit">
								{{item.spuName}}
								</div>
								<div class="text" v-html="item.spuDesc">
								</div>
								<div class="jiage">
								<span class="name">¥ {{item.spuPrice}}</span>
								<div class="label">
									<span v-for="(it1,in1) in item.label" :key="in1">{{it1}}</span>
								</div>
								</div>
							</div>
							<div class="f_jut">
								<div class="f_icon">
								
								<a href="javascript:;" v-if="toglecollect" @click="productStort(index,item.isCollect)" :class="{li:true,shou:true,on:item.isCollect == 0 || item.isCollect == null?false:true}">
									<img src="../assets/images/wushouchagn1.png" alt="">
									<img src="../assets/images/wushouchagn1-1.png" alt="">
								</a>
								<router-link :to="{name: 'Productlistinfo',params:{id:item.id}}" class="li"><img src="../assets/images/fgouwuimgf1.png" alt=""></router-link>
								</div>
								<div class="fz_but" @click="proaltShow()">
								<div class="but">
									去APP购买
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
    name: "Search",
    components:{
        Header,
        Footer,
    },
    data(){
        return {
           	xie: "结果",
		   	keywordVal: "",
			prolist:[],
			toglecollect: false,
			searProData: JSON.parse(sessionStorage.getItem("proData")) || '', //产品搜索结果
			searBrandData: JSON.parse(sessionStorage.getItem("branData")) || '', //品牌搜索结果
			size: 10, //每页条数
			current: 1, //当前页
			proCount: JSON.parse(sessionStorage.getItem("proCound")) || '',
			brandCount: JSON.parse(sessionStorage.getItem("brandCount")) || '',
		}
    },
	mounted(){
		this.keywordVal = this.$route.query.title;
		this.xie = this.$route.query.title;
		document.title = this.$route.query.title;
		this.getSearchData();
		
		// this.keySubmit();
		// if(this.$store.state.loginStore.login){
		// 	this.toglecollect=true;
		// }else{
		// 	this.toglecollect=false;
		// }
	},
	methods:{
		keySubmit(){
			this.$axios.post('/api2/portal/product/spuInfo/findByKeyword',{
				keyword: this.keywordVal,
				size: 10000
			}).then(res => {
				console.log(res);
				if(res.data!=undefined){
					this.prolist = res.data.data;
				}else{
					this.prolist=""
				}
			}).catch(error => {
				console.log(error)
			});
		},
		productStort(index,isCollect){  //收藏
			if(isCollect==null || isCollect==1){
			isCollect=0;
			}else{
			isCollect=1;
			}
			let url;
			if(this.$store.state.loginStore.login){
			/* 登录的接口 */
			url = "/api2/portal/auth/product/spuInfo/collectSpu";
			}else{
			/* 没有登录的接口 */
			url = "/api2/portal/product/spuInfo/collectSpu";
			}
			this.$axios.post(url,{
			spuId: this.prolist[index].id,
			type: isCollect
			}).then(res => {
			if(res.code=="000000"){
				if(this.prolist[index].isCollect == 0 || this.prolist[index].isCollect == null){
				this.prolist[index].isCollect=1;
				this.$message({
					message: '收藏成功!',
					type: 'success',
				});
				}else {
				this.prolist[index].isCollect=0;
				this.$message({
					message: '取消收藏!',
					type: 'success',
				});
				}
				
			}
			}).catch(error => {
			console.log(error);
			});
			// this.prolist[index].shou=!this.prolist[index].shou;
		},
		searchSubmit(){  //搜索触发
				this.getSearchData();
		},
		getSearchData(){	 //获取搜索结果
			if(sessionStorage.getItem("search") != this.keywordVal){
				sessionStorage.setItem("search",this.keywordVal);
				this.$router.push({query:{title: this.keywordVal}});
				this.$axios.post('/api2/portal/product/spuInfo/findByKeyword',{ //获取产品
					keyword: this.keywordVal,
					current: this.current,
					size: this.size,
				}).then( res => {
					if(res.code == "000000"){
						if(res.data){
							this.searProData = res.data.data;
							this.proCount = res.data.total;
							sessionStorage.setItem("proData",JSON.stringify(res.data.data));
							sessionStorage.setItem("proCound",JSON.stringify(res.data.total));
						}else{
							this.searProData=[];
							sessionStorage.removeItem("proData");
						}
					}else{
						this.searProData=[];
						sessionStorage.removeItem("proData");
					}
				}).catch( error =>{
					console.log(error);
				});

				this.$axios.post('/api2/portal/product/brand/getByKeyword',{ //获取品牌
					keyword: this.keywordVal,
					current: this.current,
					size: this.size,
				}).then( res => {
					if(res.code == "000000"){
						if(res.data){
							this.searBrandData = res.data.data;
							this.brandCount = res.data.total;
							sessionStorage.setItem("branData",JSON.stringify(res.data.data));
							sessionStorage.setItem("brandCount",JSON.stringify(res.data.total));
						}else{
							this.searBrandData=[];
							sessionStorage.removeItem("branData");
						}
					}else{
						this.searBrandData=[];
						sessionStorage.removeItem("branData");
					}
				}).catch( error =>{
					console.log(error);
				});
			}
		},
		addEmphasis(_val){ //转换
			var reg = new RegExp(this.keywordVal, "g");
			let html =  _val.replace(reg, "<span>"+this.keywordVal+"</span>");
			return html;
		},
	},
	watch:{
		$route(to,from){
			// this.$router.go(0);
			// console.log(this.$router);
			this.xie = this.$route.query.title;
			this.keywordVal = this.$route.query.title;
			document.title = this.$route.query.title;
			this.getSearchData();
		}
	}
}
</script>
